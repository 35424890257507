import { useEffect } from 'react';
import { MSG_CLIENT_ERROR } from '@/services/error/messages';

const toastify = () =>
  import(/* webpackChunkName: "toastify" */ '@/services/toastify');

// hook para mostrar error en el input de un formulario
// se utiliza el metodo setError de react-hook-form
// formRef     -> es una referencia al formulario, asi podemos acceder al setError
// error       -> es un objeto { type: String, message: String }
// onUnmount:  -> es un callback que podemos llamar cuando se desmonta el componente

const useFormError = (formRef, errors) => {
  // mostramos el error en el input cuando el parametro error cambia de valor
  // error.type hace referencia al name del input en el formulario
  // setError(inputName, config)
  useEffect(() => {
    if (errors && formRef.current) {
      errors.forEach((x) => {
        if (x.error_type !== 'general') {
          formRef.current.setErrors({
            ...errors,
            [x.error_type]: x.error_description
          });
        } else {
          // si el error es general envia el toast
          const message = x.error_description || MSG_CLIENT_ERROR;
          toastify().then((toast) => toast.error(message));
        }
      });
    }
  }, [errors]);
};

export default useFormError;
