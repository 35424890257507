import PropTypes from 'prop-types';
import styles from './column.module.scss';

const Column = ({ children, gap }) => (
  <div className={styles.column} style={{ gap }}>
    {children}
  </div>
);

Column.propTypes = {
  children: PropTypes.node.isRequired,
  gap: PropTypes.string
};

Column.defaultProps = {
  gap: '3rem'
};

export default Column;
