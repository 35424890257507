/* eslint-disable no-alert */
import { useDispatch } from 'react-redux';
import { useState, useRef } from 'react';
import Router from 'next/router';
import MaxContainer from '@/components/atoms/MaxContainer';
import Layout from '@/components/Layout';
// import styles from './login.module.scss';
import isValidEmail from '@/services/formValidators/isValidEmail';
import { Form } from '@/components/organisms';
import {
  Button,
  Container,
  Divider,
  Icon,
  Input,
  Link,
  Text
} from '@/components/atoms';
import Column from '@/components/atoms/Column';
import auth from '@/api/auth';
import sentry from '@/services/sentry';
import { loadUserInfo } from '@/redux/checkout/checkoutReducer';
import useCheckoutRouter from '@/hooks/checkoutRouter';
import styles from './login.module.scss';
import useFormError from '@/hooks/useFormError';

const Login = () => {
  const dispatch = useDispatch();
  const checkout = useCheckoutRouter('login');
  const [errors, setErrors] = useState([]);
  const formRef = useRef(null);

  const onSubmit = async ({ email, password }) => {
    const response = await auth.login(email.toLowerCase(), password);
    sentry.setUser({ email });
    if (response.success) {
      response.data.persisted = true;
      dispatch(loadUserInfo(response.data));
      checkout();
    } else {
      setErrors(response.errors);
    }
  };
  useFormError(formRef, errors);

  return (
    <Layout
      title="Ingresá tu email y contraseña para entrar a tu cuenta"
      type="onlyBrand"
      description="Accede a tu cuenta en Vaypol y disfruta de una experiencia personalizada. Ingresa para explorar nuestras ofertas y servicios. ¡Bienvenido de nuevo a Vaypol!"
    >
      <Container margin="0px">
        <MaxContainer max={600}>
          <Container
            className=" isBackgrounded"
            margin="0px"
            style={{ padding: '2rem' }}
          >
            <Column gap="0px">
              <Icon
                type="logoTransparent"
                color="var(--color-primary)"
                width="6rem"
              />
              <Text
                textColor="white"
                weight="bold"
                center
              >
                Ingresá tu email y contraseña para entrar a tu cuenta
              </Text>
            </Column>
            <Divider
              style={{ marginTop: '20px', marginBottom: '20px' }}
              color="grey"
            />
            <Form
              getRef={(ref) => {
                formRef.current = ref.current;
              }}
              onSubmit={onSubmit}
            >
              <Input
                className="fullWidth"
                defaultValue=""
                id="Login Email Input"
                name="email"
                required
                title="Email"
                type="email"
                validations={[isValidEmail]}
                autoFocus
              />

              <Input
                className="fullWidth"
                defaultValue=""
                id="Login Password Input"
                name="password"
                type="password"
                required
                title="Password"
                validations={[
                  (input) => {
                    if (input?.length > 5) return { isValid: true };
                    if (input?.length < 1)
                      return {
                        isValid: false,
                        errorMessage: 'Este campo es obligatorio'
                      };
                    return {
                      isValid: false,
                      errorMessage: 'Este campo debe tener 6 caracteres'
                    };
                  }
                ]}
              />
              <div
                className="fullWidth"
                style={{ justifySelf: 'right' }}
              >
                <Link href="/forgot-password">
                  <Text
                    variant="span"
                    textSize="xs"
                    colored
                  >
                    ¿Olvidaste tu contraseña?
                  </Text>{' '}
                </Link>
              </div>
              <div className={`${styles.keypad} fullWidth`}>
                <Button
                  className="fullWidth"
                  id="Login Iniciar sesion"
                  label="Iniciar Sesión"
                  submit
                  variant="primary"
                />
                <Button
                  className="fullWidth"
                  id="Login crear cuenta"
                  label="Crear cuenta"
                  onClick={() => Router.push('/signup')}
                  variant="secondary"
                />
              </div>
            </Form>
          </Container>
        </MaxContainer>
      </Container>
    </Layout>
  );
};

export default Login;
